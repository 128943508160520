import { useState, useEffect, useRef } from 'react';

export const useAnimatedText = (text: string, speed: number = 30) => {
  const [displayedText, setDisplayedText] = useState('');
  const indexRef = useRef(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisplayedText((prev) => {
        if (indexRef.current < text.length) {
          indexRef.current++;
          return text.slice(0, indexRef.current);
        }
        clearInterval(intervalId);
        return prev;
      });
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed]);

  return displayedText;
};