import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnimatedText } from '../hooks/useAnimatedText';
import AirOps from '@airops/airops-js';
import Input from './Input';
import Button from './Button';
import LogoutButton from './LogoutButton';
import { STRINGS } from '../constants/strings';
import styled from 'styled-components'

const AIROPS_WORKSPACE_ID = parseInt(process.env.REACT_APP_AIROPS_WORKSPACE_ID || '', 10);

const AnimatedTextContainer = styled.pre<{ isComplete: boolean }>`
  opacity: ${props => props.isComplete ? 1 : 0.7};
  transition: opacity 0.3s ease-in-out;
`;

const FadingText = styled.pre<{ isVisible: boolean }>`
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
`;

function Dashboard() {
  const [user, setUser] = useState<any>(null);
  const [url, setUrl] = useState<string>('');
  const [icpResult, setIcpResult] = useState<any>(null);
  const [streamingResult, setStreamingResult] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fullStreamingResult, setFullStreamingResult] = useState('');
  const [isStreamingComplete, setIsStreamingComplete] = useState(false);
  const [showFullResult, setShowFullResult] = useState(false);
  const animatedStreamingResult = useAnimatedText(fullStreamingResult, 30);
  const navigate = useNavigate();


  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('/api/auth/user', {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error('Not authenticated');
        }
        const userData = await response.json();
        setUser(userData);
      } catch (error) {
        navigate('/');
      }
    };

    checkAuth();
  }, [navigate]);

  useEffect(() => {
    console.log('Streaming result updated:', streamingResult);
  }, [streamingResult]);
  
  useEffect(() => {
    console.log('ICP result updated:', icpResult);
  }, [icpResult]);

  useEffect(() => {
    if (isStreamingComplete) {
      const timer = setTimeout(() => setShowFullResult(true), 500);
      return () => clearTimeout(timer);
    }
  }, [isStreamingComplete]);


  const handleGenerateIcp = async () => {
    setIsLoading(true);
    setError(null);
    setIsStreamingComplete(false);
    setShowFullResult(false);
    setFullStreamingResult('');
    
    try {
  
      // Fetch the hashed user ID from your backend    
      const hashResponse = await fetch('/api/app/get-ao-user-hash', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.id }),
        credentials: 'include',
      });

      if (!hashResponse.ok) {
        throw new Error(`Failed to get hashed user ID: ${hashResponse.statusText}`);
      }

      const { hashedUserId } = await hashResponse.json();

      const airops = AirOps.identify({
        userId: user.id,
        workspaceId: 20276,
        hashedUserId: hashedUserId
      });
      const response = await airops.apps.execute({
        appId: "2816cc13-4c3c-4541-b347-4cc562df8558",
        payload: {
          "inputs": {
            "site_url": url
          }
        },
        stream: true,
        streamCallback: (data: { content: string }) => {
          console.log('Received stream data:', data);
          setFullStreamingResult(prevResult => prevResult + (data.content || JSON.stringify(data)));
        },
        streamCompletedCallback: () => {
          setIsStreamingComplete(true);
        },
      });

  
      // Wait for the result
      const result = await response.result();
      console.log(result)
  
    } catch (error) {
      console.error('Error generating ICP:', error);
      setError(`Failed to generate ICP: ${error}`);
    } finally {
      setIsLoading(false);
    }
    
  };

  if (!user) return null;

  return (
    <div>
      <h1>{STRINGS.DASHBOARD.WELCOME}</h1>
      <LogoutButton onLogout={() => setUser(null)} />
      <Input 
        id='icpUrl' 
        placeholder='wfco.co' 
        type="text" 
        value={url} 
        onChange={e => setUrl(e.target.value)}
      >
        {STRINGS.DASHBOARD.INPUT_LABEL}
      </Input>
      <Button type="button" onClick={handleGenerateIcp} disabled={isLoading || isNaN(AIROPS_WORKSPACE_ID)}>
        {isLoading ? 'Generating...' : 'Generate ICP'}
      </Button>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {fullStreamingResult && (
        <div style={{ position: 'relative' }}>
          <h3>Streaming Result:</h3>
          <AnimatedTextContainer isComplete={isStreamingComplete}>
            {animatedStreamingResult}
          </AnimatedTextContainer>
          <FadingText isVisible={showFullResult}>
            {fullStreamingResult}
          </FadingText>
        </div>
      )}
      {icpResult && (
        <div>
          <h3>Final ICP Result:</h3>
          <pre>{icpResult}</pre>
        </div>
      )}
    </div>
  );
}

export default Dashboard;